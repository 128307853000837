<template>
  <div>
    <el-card>
      <el-button type="primary" @click="addDialog = true" size="small">添加</el-button>
      <el-table border :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column prop="id" align="center" label="id" width="100"></el-table-column>
        <el-table-column prop="name" align="center" label="名称"></el-table-column>
        <el-table-column prop="type" align="center" label="类型">
          <template slot-scope="scope">
            <span>{{ scope.row.type | type }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="imgUrl" align="center" label="图片/视频封面" width="180">
          <template slot-scope="scope">
            <el-image style="height: 100px" :src="scope.row.imgUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="videoUrl" label="视频" width="300" align="center">
          <template slot-scope="scope">
            <video v-if="scope.row.type == 1" controls :src="scope.row.videoUrl" class="video"></video>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="跳转地址" width="180" align="center"></el-table-column>
        <el-table-column label="端口" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.showPort | showPort }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.showType | showType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开启时间" align="center" width="160"></el-table-column>
        <el-table-column prop="endTime" align="center" label="关闭时间" width="160"></el-table-column>
        <el-table-column prop="status" label="状态" align="center" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? "启用" : "停用" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sortVal" label="排序值" align="center" width="100"></el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" width="160"></el-table-column>
        <el-table-column align="center" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="openSet(scope.row, scope.row.id)">编辑
            </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="delSchool(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="querInfo.current" :page-sizes="[5, 10, 15]" :page-size="querInfo.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
    <el-dialog title="添加轮播图" :visible.sync="addDialog" :close-on-click-modal="false" width="730px" @close="closeAdd"
               :before-close="closeAdd" top="5vh">
      <el-form label-position="right" label-width="120px" :rules="rules" ref="carouselForm" :model="carouselForm">
        <el-form-item label="名称：" prop="name">
          <el-input v-model="carouselForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-radio-group v-model="carouselForm.type">
            <el-radio label="0">图片</el-radio>
            <el-radio label="1">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转地址：" prop="url" v-if="carouselForm.type == '0'">
          <el-input v-model="carouselForm.url" placeholder="请输入跳转地址"></el-input>
        </el-form-item>
        <el-form-item :label="carouselForm.type == '0'?'上传图片：':'视频封面：'" prop="imgUrl">
          <el-upload ref="uploadFile1" class="avatar-uploader" action="/api/file/upload" :show-file-list="false"
                     :on-success="handleAvatarSuccess" name="file" :multiple="false" :limit="1">
            <img v-if="carouselForm.imgUrl != ''" :src="carouselForm.imgUrl"
                 class="avatar"/>
            <i v-if="carouselForm.imgUrl == ''" class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频：" prop="videoUrl" v-if="carouselForm.type == '1'">
          <el-upload ref="uploadFile2" class="avatar-uploader" action="/api/file/upload" :show-file-list="false"
                     :on-success="handlePosterSuccess" name="file" :multiple="false" :limit="1">
            <video controls v-if="carouselForm.videoUrl" :src="carouselForm.videoUrl" class="video"></video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="开启时间：" prop="startTime">
          <el-date-picker v-model="carouselForm.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                          placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="关闭时间：" prop="endTime">
          <el-date-picker v-model="carouselForm.endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                          placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="端口：" prop="showPort">
          <el-radio-group v-model="carouselForm.showPort">
            <el-radio :label="0">小程序</el-radio>
            <el-radio :label="1">pc</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类型：" prop="showType" v-if="carouselForm.type == '0'">
          <el-radio-group v-model="carouselForm.showType">
            <el-radio :label="0">只展示</el-radio>
            <el-radio :label="1">站内跳转</el-radio>
            <el-radio :label="2">站外跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序值：">
          <el-input type="number" v-model="carouselForm.sortVal" placeholder="请输入排序值"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-switch v-model="status" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
        <div style="text-align: right">
          <el-button @click="closeAdd('carouselForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="编辑" :visible.sync="setDialog" :close-on-click-modal="false" width="730px" top="5vh">
      <el-form label-position="right" label-width="120px" :rules="rules" ref="carouselForm" :model="updataMenuForm">
        <el-form-item label="名称：" prop="name">
          <el-input v-model="updataMenuForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-radio-group v-model="updataMenuForm.type">
            <el-radio :label="0">图片</el-radio>
            <el-radio :label="1">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转地址：" v-if="updataMenuForm.type == 0">
          <el-input v-model="updataMenuForm.url" placeholder="请输入跳转地址"></el-input>
        </el-form-item>
        <el-form-item :label="updataMenuForm.type == 0?'上传图片：':'上传视频封面：'" prop="imgUrl">
          <el-upload class="avatar-uploader" action="/api/file/upload" :show-file-list="false"
                     :on-success="handleAvatarSuccess" name="file">
            <img v-if="updataMenuForm.imgUrl != ''" :src="updataMenuForm.imgUrl"
                 class="avatar"/>
            <i v-if="updataMenuForm.imgUrl == ''" class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频：" prop="videoUrl" v-if="updataMenuForm.type == 1">
          <el-upload ref="uploadFile2" class="avatar-uploader" action="/api/file/upload" :show-file-list="false"
                     :on-success="handlePosterSuccess" name="file" :multiple="false" :limit="1">
            <video controls v-if="updataMenuForm.videoUrl" :src="updataMenuForm.videoUrl" class="video"></video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="启用时间：">
          <el-date-picker v-model="updataMenuForm.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                          placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="禁用时间：">
          <el-date-picker v-model="updataMenuForm.endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                          placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="端口：">
          <el-radio-group v-model="updataMenuForm.showPort">
            <el-radio :label="0">小程序</el-radio>
            <el-radio :label="1">pc</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类型：">
          <el-radio-group v-model="updataMenuForm.showType">
            <el-radio :label="0">只展示</el-radio>
            <el-radio :label="1">站内跳转</el-radio>
            <el-radio :label="2">站外跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序值：">
          <el-input type="number" v-model="updataMenuForm.sortVal" placeholder="请输入排序值"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-switch v-model="setStatus" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
        <div style="text-align: right">
          <el-button @click="setDialog = false">取 消</el-button>
          <el-button type="primary" @click="handleDataMenus(carouselForm)">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {carouselPage, carouselAddOnSet, carouselDel} from "@/apis/banner";

export default {
  data() {
    return {
      querInfo: {
        current: 1,
        size: 10,
      },
      // 总数据条数
      total: 0,
      carouselForm: {
        id: "",
        name: "",
        url: "",
        startTime: "", //启用时间
        endTime: "", //禁用时间
        imgUrl: "", //图片/视频封面url地址
        videoUrl: "",//视频地址url
        type: "0", //类型
        schoolId: "", //学校id
        showPort: 0, //显示端口(0=小程序 1=pc)
        showType: 0, //显示类型(0=只展示 1=站内跳转 2=站外跳转)
        status: 0, //状态(0=启用 1=停用)
        sortVal: 1 //排序值
      },
      status: true,
      setStatus: true, //编辑
      //编辑
      updataMenuForm: {
        name: "",
        url: "",
        startTime: "", //启用时间
        endTime: "", //禁用时间
        imgUrl: "", //图片/视频封面url地址
        videoUrl: "",//视频地址url
        type: 0, //类型
        schoolId: "", //学校id
        showPort: 0, //显示端口(0=小程序 1=pc)
        showType: 0, //显示类型(0=只展示 1=站内跳转 2=站外跳转)
        status: 0, //状态(0=启用 1=停用)
        sortVal: 1 //排序值
      },
      rules: {
        name: [{required: true, message: "请输入名称", trigger: "blur"}],
        imgUrl: [{required: true, message: "请选择图片", trigger: "blur"}],
        videoUrl: [{required: true, message: "请选择一个视频", trigger: "blur"}]
      },
      addDialog: false,
      setDialog: false,
      tabId: "",
      tableData: [],
      loading: false,
    };
  },
  filters: {
    showPort(value) {
      return value == 0 ? "小程序" : "pc";
    },
    showType(value) {
      // 0=只展示 1=站内跳转 2=站外跳转
      return value == 0 ? "只展示" : value == 1 ? "站内跳转" : "站外跳转";
    },
    type(value) {
      return value == 0 ? "图片" : "视频";
    }
  },
  watch: {
    "carouselForm.type"(newVal, oldVal) {
      this.carouselForm.imgUrl = "";
      this.carouselForm.videoUrl = "";
    }
  },
  created() {
    this.carouselPage();
  },
  methods: {
    //获取轮播图列表分页
    carouselPage() {
      this.loading = true;
      carouselPage(this.querInfo).then((res) => {
        this.querInfo.size = res.data.size;
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },
    //提交添加
    submitForm() {
      this.$refs.carouselForm.validate((valid) => {
        if (valid) {
          if (this.status) {
            this.carouselForm.status = "0";
          } else {
            this.carouselForm.status = "1";
          }
          carouselAddOnSet(this.carouselForm).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.carouselPage();
              this.addDialog = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // //编辑
    openSet(row, id) {
      console.log(row)
      this.setDialog = true;
      this.updataMenuForm = row;
      if (row.status == 0) {
        this.setStatus = true;
      } else {
        this.setStatus = false;
      }
    },
    // 修改数据
    handleDataMenus() {
      if (this.setStatus) {
        this.updataMenuForm.status = 0;
      } else {
        this.updataMenuForm.status = 1;
      }
      carouselAddOnSet(this.updataMenuForm).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.setDialog = false;
          this.carouselPage();
        }
      });
    },
    // 删除
    async delSchool(id) {
      const confirmResult = await this.$confirm(
          "此操作将删除轮播图, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return;
      }
      carouselDel({id: id}).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.carouselPage();
      });
    },

    handleAvatarSuccess(res, file) {
      console.log(res, file)
      this.carouselForm.imgUrl = res.data;
      this.updataMenuForm.imgUrl = res.data;
      this.$refs.uploadFile1.clearFiles();

    },
    handlePosterSuccess(res, file) {
      console.log(res, file)
      this.carouselForm.videoUrl = res.data;
      this.updataMenuForm.videoUrl = res.data
      this.$refs.uploadFile2.clearFiles();
    },
    closeAdd() {
      this.$refs["carouselForm"].resetFields();
      this.addDialog = false;
    },
    // 监听 pagesize 改变
    handleSizeChange(newSize) {
      this.querInfo.size = newSize;
      this.carouselPage();
    },
    // 监听 pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.current = newPage;
      this.carouselPage();
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 400px;
  height: 178px;
  display: block;
}

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  flex: 1;
  border: 1px dashed #d9d9d9;
}
</style>