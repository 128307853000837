// 首页管理模块
import axios from 'axios'
import QS from 'qs'
//添加和修改轮播图
export const carouselAddOnSet = params => {
    return axios.post(`/api/carousel/addOnSet`, QS.stringify(params)).then(res => res.data)
}
//轮播图删除
export const carouselDel = params => {
    return axios.post(`/api/carousel/del`, QS.stringify(params)).then(res => res.data)
}
//轮播图分页
export const carouselPage = params => {
    return axios.post(`/api/carousel/page`, QS.stringify(params)).then(res => res.data)
}